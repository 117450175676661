import CloseIcon from '@mui/icons-material/Close';
import {
  List,
  ListItemText,
  Typography,
  Link,
  Divider,
  Grid,
  ListItem,
  Button,
  DialogContent,
  IconButton,
  Dialog,
  DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';

export type ScanResultItem = {
  brokerName: string;
  searchResultUrl: string;
  profiles: Array<{
    url: string;
    matchRate: number;
    data: Record<string, unknown>;
  }>;
};

export type ResultListProps = {
  resultItems: Array<ScanResultItem>;
};

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialog-paper': {
    maxWidth: '80%',
    minWidth: '50%',
  },
}));

export const ResultList = (props: ResultListProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Record<string, unknown> | null>(null);
  const [isPending, startTransition] = React.useTransition();

  const handleClickOpen = (newData: Record<string, unknown>) => {
    setData(newData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    startTransition(() => {
      setTimeout(() => {
        setData(null);
      }, 300);
    });
  };

  return (
    <>
      <List dense>
        {props.resultItems.map((r, i) => (
          <React.Fragment key={r.brokerName}>
            <ListItem divider>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6">{r.brokerName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" align="right">
                        {`(${r.profiles.length} profiles)`}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Typography variant="body2">
                    {`Search result: `}
                    <Link target="_blank" href={r.searchResultUrl}>
                      {r.searchResultUrl}
                    </Link>
                  </Typography>
                }
              />
            </ListItem>
            {r.profiles.map((p, j) => (
              <ListItem sx={{pl: 4}} key={j}>
                <ListItemText
                  primary={
                    <Link key={j} target="_blank" href={p.url}>
                      <Typography variant="subtitle2">{p.url}</Typography>
                    </Link>
                  }
                  secondary={
                    <>
                      <Typography
                        color="black"
                        sx={{fontWeight: 500, fontSize: '0.875rem'}}
                      >{`Match rate: ${p.matchRate}`}</Typography>
                      <Button variant="outlined" onClick={() => handleClickOpen(p.data)} sx={{mt: 1}}>
                        Available data
                      </Button>
                    </>
                  }
                />
              </ListItem>
            ))}
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <BootstrapDialog onClose={handleClose} aria-labelledby="data-dialog-title" open={open}>
        <DialogTitle sx={{m: 0, p: 2}} id="data-dialog-title">
          Available Data
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
