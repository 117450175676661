import {Grid, Typography, TextField, MenuItem, Button, InputAdornment} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';
import React from 'react';

import {StateCode, getAnyUsStateCode, getUsStateName, usStateCodes} from '../states';

export type ProfileFormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: Dayjs;
  state: StateCode;
  city: string;
  phone: string;
  email: string;
};

export type ProfileFormProps = {
  initialData?: Partial<ProfileFormData>;
  disabled?: boolean;
  onSubmit: (data: ProfileFormData) => void;
};

type ProfileFormErrors = Map<keyof ProfileFormData, string>;

const NAME_REGEXP = /^[a-zA-Z-]{2,25}$/;
const FIRST_NAME_REGEXP = NAME_REGEXP;
const MIDDLE_NAME_REGEXP = NAME_REGEXP;
const LAST_NAME_REGEXP = NAME_REGEXP;
const CITY_REGEXP = /^[a-zA-Z -]{2,30}$/;
const PHONE_REGEXP = /^\d{10}$/;
const EMAIL_REGEXP = /^\S+@\S+$/;

export const ProfileForm = (props: ProfileFormProps) => {
  const [firstName, setFirstName] = React.useState<string>(props.initialData?.firstName ?? '');
  const [middleName, setMiddleName] = React.useState<string>(props.initialData?.middleName ?? '');
  const [lastName, setLastName] = React.useState<string>(props.initialData?.lastName ?? '');
  const [birthDate, setBirthDate] = React.useState<Dayjs | null>(props.initialData?.birthDate ?? null);
  const [state, setState] = React.useState<StateCode>(props.initialData?.state ?? getAnyUsStateCode());
  const [city, setCity] = React.useState<string>(props.initialData?.city ?? '');
  const [phone, setPhone] = React.useState<string>(props.initialData?.phone ?? '');
  const [email, setEmail] = React.useState<string>(props.initialData?.email ?? '');

  const [errors, setErrors] = React.useState<ProfileFormErrors>(new Map());

  const validate = (): ProfileFormErrors => {
    const errors = new Map();

    if (!firstName.match(FIRST_NAME_REGEXP)) {
      errors.set('firstName', `First name must match ${FIRST_NAME_REGEXP}`);
    }

    if (middleName && !middleName.match(MIDDLE_NAME_REGEXP)) {
      errors.set('middleName', `Middle name must match ${MIDDLE_NAME_REGEXP}`);
    }

    if (!lastName.match(LAST_NAME_REGEXP)) {
      errors.set('lastName', `Last name must match ${LAST_NAME_REGEXP}`);
    }

    if (!state) {
      errors.set('state', 'State code is required');
    }

    if (!city.match(CITY_REGEXP)) {
      errors.set('city', `City must match ${CITY_REGEXP}`);
    }

    if (phone && !phone.match(PHONE_REGEXP)) {
      errors.set('phone', `Phone must match ${PHONE_REGEXP}`);
    }

    if (email && !email.match(EMAIL_REGEXP)) {
      errors.set('email', `Email must match ${EMAIL_REGEXP}`);
    }

    return errors;
  };

  const onSubmitClick = () => {
    const errors = validate();
    setErrors(errors);

    if (errors.size === 0) {
      props.onSubmit({
        firstName,
        middleName,
        lastName,
        birthDate: birthDate as Dayjs,
        state,
        city,
        phone,
        email,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Please fill form and we will scan data brokers for you personal data</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="First Name"
          required
          disabled={props.disabled}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          error={errors.has('firstName')}
          helperText={errors.get('firstName')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Middle Name"
          disabled={props.disabled}
          value={middleName}
          onChange={e => setMiddleName(e.target.value)}
          error={errors.has('middleName')}
          helperText={errors.get('middleName')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Last Name"
          required
          disabled={props.disabled}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          error={errors.has('lastName')}
          helperText={errors.get('lastName')}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          label="Birth Date"
          disableFuture
          defaultValue={birthDate}
          value={birthDate}
          onChange={setBirthDate}
          slotProps={{
            textField: {
              required: false,
              disabled: props.disabled,
              error: errors.has('birthDate'),
              helperText: !birthDate && errors.get('birthDate'),
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="State"
          required
          disabled={props.disabled}
          select
          value={state}
          onChange={e => setState(e.target.value as StateCode)}
          error={errors.has('state')}
          helperText={errors.get('state')}
        >
          {usStateCodes.map(code => (
            <MenuItem key={code} value={code}>
              {getUsStateName(code)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={5}>
        <TextField
          fullWidth
          label="City"
          required
          disabled={props.disabled}
          value={city}
          onChange={e => setCity(e.target.value)}
          error={errors.has('city')}
          helperText={errors.get('city')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Phone"
          disabled={props.disabled}
          InputProps={{
            startAdornment: <InputAdornment position="start">+1</InputAdornment>,
          }}
          value={phone}
          onChange={e => setPhone(e.target.value)}
          error={errors.has('phone')}
          helperText={errors.get('phone')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Email"
          disabled={props.disabled}
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={errors.has('email')}
          helperText={errors.get('email')}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={onSubmitClick}
          disabled={props.disabled}
        >
          Scan
        </Button>
      </Grid>
    </Grid>
  );
};
